<template>
  <div class="my-project-requests-request-collaterals">
    <data-loading :loading="dataLoading" :lock="true"/>
    <form @input="validateForm" @change="validateForm" @submit.stop.prevent="submitForm">
      <div v-for="(collateral, index) in collaterals">
        <div class="text-right">
          <a @click.stop.prevent="removeCollateral(index)" class="text-danger" href="#">
            <i class="bi-x"></i>
          </a>
        </div>
        <div class="h6">Недвижимость</div>
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label class="text-muted" :for="'collaterals-' + index + '-cadastral-number'">Кадастровый номер</label>
              <input v-model="collateral.cadastralNumber"
                     class="form-control form-control-lg"
                     :id="'collaterals-' + index + '-cadastral-number'"
                     type="text"
                     placeholder="00:00:0000000:000"
                     required>
              <input-errors :errors="errors['collaterals.' + index + '.cadastralNumber']"/>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div class="form-group">
              <label class="text-muted" :for="'collaterals-' + index + '-address'">Адрес объекта</label>
              <input v-model="collateral.address"
                     class="form-control form-control-lg"
                     :id="'collaterals-' + index + '-address'"
                     type="text"
                     placeholder="Адрес объекта"
                     required>
              <input-errors :errors="errors['collaterals.' + index + '.address']"/>
            </div>
          </div>
        </div>
        <div class="h6 mt-3">Собственник объекта</div>
        <div class="form-group">
          <div class="input-group">
            <div class="custom-control custom-radio">
              <input v-model="collateral.ownerType"
                     :value="individual"
                     type="radio"
                     class="custom-control-input"
                     :id="'collaterals-' + index + '-owner-type-individual'">
              <label class="custom-control-label" :for="'collaterals-' + index + '-owner-type-individual'">Физ. лицо</label>
            </div>
            <div class="custom-control custom-radio ml-4">
              <input v-model="collateral.ownerType"
                     :value="legalEntity"
                     type="radio"
                     class="custom-control-input"
                     :id="'collaterals-' + index + '-owner-type-legal-entity'">
              <label class="custom-control-label" :for="'collaterals-' + index + '-owner-type-legal-entity'">Юр. лицо</label>
            </div>
          </div>
          <input-errors :errors="errors['collaterals.' + index + '.ownerType']"/>
        </div>
        <div class="form-group">
          <label class="text-muted">Прикрепите следующие документы</label>
        </div>
        <div class="row">
          <div class="col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6 offset-sm-1">
            <div class="form-group">
              <file-upload :files="collateral.passport">
                Прикрепить фото паспорта. Первую страницу и страницу с пропиской
              </file-upload>
              <input-errors :errors="errors['collaterals.' + index + '.passport']"/>
            </div>
            <div v-if="collateral.ownerType === legalEntity" class="form-group">
              <file-upload :files="collateral.registrationCertificate">
                Свидетельство о гос. регистрации или выписка из ЕГРН
              </file-upload>
              <input-errors :errors="errors['collaterals.' + index + '.registrationCertificate']"/>
            </div>
            <div class="form-group">
              <file-upload :files="collateral.collateralDocument">
                Правоустанавливающие документы на предмет залога
              </file-upload>
              <input-errors :errors="errors['collaterals.' + index + '.collateralDocument']"/>
            </div>
            <div class="form-group">
              <file-upload :files="collateral.registeredPersonsCertificate">
                Справка о зарегистрированных лицах
              </file-upload>
              <input-errors :errors="errors['collaterals.' + index + '.registeredPersonsCertificate']"/>
            </div>
          </div>
        </div>
        <div class="h6 mt-3">Фотографии объекта</div>
        <div class="row">
          <div class="col-12 col-md-9 col-lg-7 col-xl-6">
            <div class="form-group">
              <file-upload :files="collateral.objectPhotos"/>
              <input-errors :errors="errors['collaterals.' + index + '.objectPhotos']"/>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mt-4">
        <button @click="addCollateral" type="button" class="btn btn-outline-success">Добавить объект</button>
      </div>
      <input-errors :errors="errors['someCollateral']"/>
      <hr>
      <div class="row">
        <div class="col-12 col-md-7 col-lg-8 col-xl-9">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input v-model="agreement" type="checkbox" class="custom-control-input" id="agreement" required>
              <label class="custom-control-label" for="agreement"></label>
              <public-link to="/page/soglasie-na-obrabotku-i-peredachu-personalnykh-dannykh"
                           target="_blank"
              >Подтверждаю получение мною согласия третьих лиц на передачу их персональных данных на обработку
              </public-link>
            </div>
            <input-errors :errors="errors['agreement']"/>
          </div>
        </div>
        <div class="col-12 col-md-5 col-lg-4 col-xl-3">
          <div class="form-group text-right">
            <router-link :to="{ name: 'myProjectRequests.request.ownership', params: { uuid } }"
                         class="btn btn-lg btn-outline-info mr-3">
              Назад
            </router-link>
            <button type="submit" class="btn btn-lg btn-success">Продолжить</button>
          </div>
        </div>
      </div>
    </form>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { DataLoading, FileUpload, InputErrors, Processing, PublicLink } from "@/components/common";
import { OwnerType } from "@/utils/api-client";
import { CollateralManager } from "../managers/CollateralManager";

const collateralManager = new CollateralManager();

export default {
  name: "Collaterals",
  components: {
    DataLoading,
    FileUpload,
    InputErrors,
    Processing,
    PublicLink
  },
  async mounted() {
    this.dataLoading = true;
    try {
      this.collaterals = await collateralManager.getList(this.uuid);
      if (this.collaterals.length === 0) {
        this.addCollateral();
      }
      this.validateForm();
    } finally {
      this.dataLoading = false;
    }
  },
  data() {
    return {
      collaterals: [],
      agreement: false,
      errors: {},
      dataLoading: false,
      processing: false
    };
  },
  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
    individual() {
      return OwnerType.INDIVIDUAL
    },
    legalEntity() {
      return OwnerType.LEGAL_ENTITY
    }
  },
  methods: {
    validateForm() {
      let numberRequiredFields = this.collaterals.reduce(
          (prev, item) => prev + 4 + (item.ownerType === OwnerType.LEGAL_ENTITY ? 1 : 0),
          3
      ); // 4 * n + someCollateral + button + agreement
      const errors = {};
      if (this.collaterals.length === 0) {
        errors["someCollateral"] = ['Должен быть указан минимум один объект'];
      } else {
        this.collaterals.forEach((collateral, index) => {
          if (!collateral.cadastralNumber) {
            errors['collaterals.' + index + '.fullName'] = ["Поле обязательное для заполнения"];
          }
          if (!collateral.address) {
            errors['collaterals.' + index + '.phone'] = ["Поле обязательное для заполнения"];
          }
          if (!collateral.ownerType) {
            errors['collaterals.' + index + '.ownerType'] = ["Поле обязательное для заполнения"];
          }
          if (collateral.passport.length === 0) {
            errors['collaterals.' + index + '.passport'] = ["Необходимо прикрепить фото паспорта"];
          }
          if (
              collateral.ownerType === OwnerType.LEGAL_ENTITY &&
              (!collateral.registrationCertificate || collateral.registrationCertificate.length === 0)
          ) {
            errors['collaterals.' + index + '.registrationCertificate'] = [
                "Необходимо прикрепить свидетельство о гос. регистрации или выписку из ЕГРН"
            ];
          }
        });
      }
      if (!this.agreement) {
        errors["agreement"] = ['Пожалуйста, отметьте это поле'];
      }
      this.errors = errors;
      const numberErrors = Object.keys(errors).length;
      this.$emit("progress", (numberRequiredFields - numberErrors - 1) / numberRequiredFields);
      return numberErrors === 0;
    },
    async submitForm() {
      if (this.validateForm()) {
        this.processing = true;
        try {
          await collateralManager.save(this.uuid, this.collaterals);
          await this.$router.push({ name: "myProjectRequests.request.documents", params: { uuid: this.uuid } });
        } finally {
          this.processing = false;
        }
      }
    },
    addCollateral() {
      this.collaterals.push({
        ownerType: OwnerType.INDIVIDUAL,
        passport: [],
        registrationCertificate: [],
        collateralDocument: [],
        registeredPersonsCertificate: [],
        objectPhotos: []
      });
      this.validateForm();
    },
    removeCollateral(index) {
      if (this.collaterals.length <= 1) {
        this.$notifications.addAlert("Должен быть указан как минимум один объект!");
      } else {
        this.$confirm("Вы хотите удалить объект?", () => this.collaterals.splice(index, 1));
      }
      this.validateForm();
    }
  }
}
</script>
