import { createUploadedFileFromUrl } from "@/utils";
import { OwnerType } from "@/utils/api-client";
import { UploadedFile } from "@/utils/api-client/models/upload/UploadedFile";
import { createProjectRequestManager } from "@/utils/di";

type Collateral = {
  readonly cadastralNumber: string;
  readonly address: string;
  readonly ownerType: OwnerType;
  readonly passport: Array<UploadedFile>;
  readonly registrationCertificate?: Array<UploadedFile>;
  readonly collateralDocument: Array<UploadedFile>;
  readonly registeredPersonsCertificate: Array<UploadedFile>;
  readonly objectPhotos: Array<UploadedFile>;
}

export class CollateralManager {
  async getList(uuid: string): Promise<Array<Collateral>> {
    const manager = await createProjectRequestManager();
    const data = await manager.getCollaterals(uuid) || [];
    return data.map((item) => ({
      cadastralNumber: item.cadastralNumber,
      address: item.address,
      ownerType: item.ownerType || OwnerType.INDIVIDUAL,
      passport: (item.passport || []).map((url) => createUploadedFileFromUrl(url)),
      registrationCertificate: (item.registrationCertificate || []).map((url) => createUploadedFileFromUrl(url)),
      collateralDocument: (item.collateralDocument || []).map((url) => createUploadedFileFromUrl(url)),
      registeredPersonsCertificate: (item.registeredPersonsCertificate || []).map((url) => createUploadedFileFromUrl(url)),
      objectPhotos: (item.objectPhotos || []).map((url) => createUploadedFileFromUrl(url))
    }));
  }

  async save(uuid: string, collaterals: Array<Collateral>): Promise<void> {
    const manager = await createProjectRequestManager();
    return manager.setCollaterals(uuid, collaterals.map((item) => ({
      cadastralNumber: item.cadastralNumber,
      address: item.address,
      ownerType: item.ownerType,
      passport: item.passport.map(({url}) => url),
      registrationCertificate: (item.registrationCertificate || []).map(({url}) => url),
      collateralDocument: item.collateralDocument.map(({url}) => url),
      registeredPersonsCertificate: item.registeredPersonsCertificate.map(({url}) => url),
      objectPhotos: item.objectPhotos.map(({url}) => url)
    })));
  }
}
